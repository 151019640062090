define("discourse/plugins/discourse-data-explorer/discourse/components/modal/query-help", ["exports", "@ember/template", "discourse/components/d-modal", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _template, _dModal, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const QueryHelp = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DModal
      @title={{i18n "explorer.help.modal_title"}}
      @closeModal={{@closeModal}}
    >
      <:body>
        {{htmlSafe (i18n "explorer.help.auto_resolution")}}
        {{htmlSafe (i18n "explorer.help.custom_params")}}
        {{htmlSafe (i18n "explorer.help.default_values")}}
        {{htmlSafe (i18n "explorer.help.data_types")}}
      </:body>
    </DModal>
  
  */
  {
    "id": "o3Am67F0",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"explorer.help.modal_title\"],null],[30,1]]],[[\"body\"],[[[[1,\"\\n      \"],[1,[28,[32,2],[[28,[32,1],[\"explorer.help.auto_resolution\"],null]],null]],[1,\"\\n      \"],[1,[28,[32,2],[[28,[32,1],[\"explorer.help.custom_params\"],null]],null]],[1,\"\\n      \"],[1,[28,[32,2],[[28,[32,1],[\"explorer.help.default_values\"],null]],null]],[1,\"\\n      \"],[1,[28,[32,2],[[28,[32,1],[\"explorer.help.data_types\"],null]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@closeModal\"],false,[]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/modal/query-help.js",
    "scope": () => [_dModal.default, _discourseI18n.i18n, _template.htmlSafe],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "query-help:QueryHelp"));
  var _default = _exports.default = QueryHelp;
});